.App {
    text-align: center;
}

.container {
    display: flex;
    justify-content: center;
}

.sideBar {
    display: flex;
    flex-direction: column;
}
