:root {
  --color-primary: #18326d;
  --color-white: #fff;
  --gray-light-1: #f5f5f5;
  --gray-light-2: #d8d8d8;
  --gray-light-3: #A1A1A1;
  --gray-light-4: #DDDDDD;
  --gray-light-5: #9A9A9A;
  --gray-light-6: #F7F7F7;
  --color-black: #000;
  --gray-dark-1: #595959;
  --gray-dark-2: #979797;
  --gray-dark-3: #555555;
  --gray-dark-4: #797272;
  --gray-dark-5: #868686;
  --color-btn: #eb6a6a;
  --color-btn-2: #f9f9f9;
  --team-color-1: #fd3131;
  --team-color-2: #fdb131;
  --team-color-3: #316ffd;
  --team-color-4: #008f1a;
  --team-color-5: #af0359;
  --team-color-6: #8e4f0f;
  --scale-color: #EB6A6A; }

@font-face {
  font-family: 'FPT Light';
  font-weight: 100;
  font-style: normal;
  src: url("../assets/fonts/FontsFree-Net-SFProDisplay-Light.ttf") format("truetype"); }

@font-face {
  font-family: 'FPT Medium';
  font-weight: 300;
  font-style: normal;
  src: url("../assets/fonts/FontsFree-Net-SFProDisplay-Medium.ttf") format("truetype"); }

@font-face {
  font-family: 'FPT Regular';
  font-weight: 400;
  font-style: normal;
  src: url("../assets/fonts/FontsFree-Net-SFProDisplay-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'FPT Heavy';
  font-weight: 400;
  font-style: normal;
  src: url("../assets/fonts/FontsFree-Net-SFProDisplay-Heavy.ttf") format("truetype"); }

@font-face {
  font-family: 'FPT SemiBold';
  font-weight: 400;
  font-style: normal;
  src: url("../assets/fonts/FontsFree-Net-SFProDisplay-Semibold.ttf") format("truetype"); }

.hidden {
  display: none; }

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

html {
  font-size: 62.5%; }

body {
  font-family: 'FPT Regular', sans-serif;
  font-weight: 400;
  line-height: 1.7;
  color: var(--color-black);
  background: var(--gray-light-1); }

h1 {
  font-size: 2rem;
  font-weight: 400; }

h2 {
  font-family: 'FPT Medium', sans-serif;
  font-size: 2rem;
  font-weight: 100; }

h3 {
  font-family: 'FPT Medium', sans-serif;
  font-size: 2rem;
  font-weight: 400;
  line-height: 1; }

p {
  font-family: 'FPT Regular', sans-serif;
  line-height: 1.5;
  font-size: 1.6rem;
  font-weight: 100; }

label {
  font-family: 'FPT Medium', sans-serif;
  font-size: 1.6rem;
  line-height: 1.2; }

.squared {
  vertical-align: super;
  font-size: 12px; }

.btn {
  background: var(--color-white);
  color: var(--color-white);
  font-size: 2rem;
  font-family: 'FPT Medium';
  width: 100%;
  border-radius: 3.5rem;
  border: solid 1px var(--gray-dark-2);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
  border-radius: 4px;
  padding: 9px 10px; }
  .btn:hover {
    color: inherit; }
  .btn--red {
    background-color: var(--color-btn); }
  .btn--blue {
    background-color: var(--color-primary); }
  .btn--not-active {
    background-color: var(--gray-dark-2); }
  .btn--pdf-download-link {
    text-decoration: none; }
  .btn--new-team {
    display: flex;
    align-items: center;
    background-color: var(--color-btn-2);
    border-style: dashed;
    border-radius: 5px;
    color: var(--color-black);
    padding: 1rem 15px;
    font-size: 18px;
    justify-content: flex-start;
    margin-bottom: 2rem; }
    .btn--new-team:before {
      content: '+';
      color: #767676;
      font-weight: 700;
      font-size: 24px;
      justify-content: flex-start;
      margin-right: 5rem; }
  .btn--determine-norms {
    background-color: var(--color-primary);
    border-radius: 5px; }
  .btn--splash {
    color: #000;
    border-radius: 35px;
    border: solid #979797 1px;
    text-decoration: none;
    width: 258px;
    height: 60px;
    font-size: 1.6rem;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 300; }
    .btn--splash:hover {
      color: #000; }

.disabled {
  background-color: var(--gray-light-3); }

.go-back {
  color: var(--gray-dark-4);
  margin-top: 2rem;
  font-size: 2rem;
  text-decoration: underline;
  cursor: pointer;
  border: none;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-family: 'FPT Medium'; }

.controls-builder {
  display: flex;
  flex-direction: column;
  width: 25%; }

.controls {
  padding-left: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 25%; }
  .controls p {
    margin-bottom: 2rem; }

.floor-planner {
  width: 75%;
  background-color: #fff;
  border-radius: 15px;
  height: calc(100vh - 200px); }

.info-scale {
  position: absolute;
  bottom: 2.6rem;
  left: 3.8rem; }

.info {
  margin-bottom: 4rem; }
  .info__input-element {
    position: relative; }
    .info__input-element:after {
      content: "";
      border: dashed 1px var(--gray-light-4);
      width: 75%;
      position: absolute;
      top: 75%;
      left: 5%;
      padding: 0 30px; }
  .info__input {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-between;
    margin: 2rem 0; }
    .info__input input {
      padding: 6px 5px;
      font-size: 1.8rem;
      border-radius: 4px;
      border: none;
      text-align: center;
      width: 200px; }
      .info__input input::before {
        content: "";
        width: 5px;
        height: 5px;
        background-color: red; }
  .info__unit {
    font-family: 'FPT SemiBold';
    color: var(--gray-light-3);
    font-size: 2rem; }
  .info__icon {
    position: absolute;
    right: 1rem; }
  .info__block {
    margin-bottom: 4rem; }

input[type="file"] {
  display: none; }

.custom-file-upload {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  margin-top: 2rem; }
  .custom-file-upload__file-name {
    flex: 1;
    padding-left: 15px; }
    .custom-file-upload__file-name label {
      text-align: left;
      font-size: 1.3rem;
      text-decoration: underline;
      font-family: 'FPT Medium'; }
  .custom-file-upload__info {
    cursor: pointer; }
  .custom-file-upload__label {
    display: flex;
    padding: 11px 12px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 13px;
    justify-content: center;
    width: 100px;
    background: #FFF;
    box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.5); }

.modal-dialog {
  max-width: 600px; }

.modal-content {
  position: relative;
  border: solid 1px var(--gray-dark-2);
  border-radius: 18px;
  padding: 30px 32px;
  padding-bottom: 6rem; }

.modal-backdrop {
  background: var(--gray-light-6); }

.modal-title {
  font-family: 'FPT SemiBold';
  font-size: 2rem; }

.modal-header {
  border: none;
  padding-bottom: 0; }
  .modal-header .close {
    position: absolute;
    padding: 0;
    margin: 0;
    right: 14px;
    bottom: 17px;
    color: #FFF;
    opacity: 1; }
    .modal-header .close:after {
      content: url("../assets/img/modal-close.svg");
      width: 2rem;
      height: 2rem;
      opacity: 1; }

.modal-body {
  margin-top: 0;
  padding-top: 0; }
  .modal-body p {
    font-size: 2rem; }

.navigation {
  text-align: center;
  position: relative; }
  .navigation button:hover {
    color: #FFF; }
  .navigation button.go-back:hover {
    color: var(--gray-dark-4); }

.palette {
  padding-top: 2rem;
  display: flex;
  flex-wrap: wrap; }
  .palette__color {
    flex: 1 0 10%;
    margin: 1px 6px; }
    .palette__color button {
      height: 3rem;
      width: 3rem;
      border-radius: 1rem;
      border: none;
      cursor: pointer; }

.pdf-controls {
  display: flex;
  justify-content: space-between;
  margin-top: 10px; }
  .pdf-controls__ccw, .pdf-controls__cw {
    padding: 5px 0;
    flex-grow: 1;
    background-color: var(--gray-dark-2);
    border-radius: 5px;
    cursor: pointer;
    height: 40px; }
    .pdf-controls__ccw:first-child, .pdf-controls__cw:first-child {
      margin-right: 15px; }

.polygons {
  margin: 0; }
  .polygons__list {
    list-style: none; }
  .polygons__item {
    display: flex;
    margin-bottom: 10px; }
  .polygons__text {
    margin: 0; }
  .polygons__color-block {
    width: 20px;
    height: 20px;
    border: solid 2px #000;
    margin-right: 10px; }

.react-pdf__message--no-data {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%); }

.react-pdf__message--error {
  font-size: 1.4rem; }

.react-pdf__Document {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 665px; }

.react-pdf__Page {
  display: flex;
  justify-content: center;
  height: 100%; }
  .react-pdf__Page__canvas {
    width: auto !important;
    height: 100% !important; }

.summary {
  border-top: dashed 1px var(--gray-dark-2); }
  .summary__line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 0;
    margin-bottom: 1rem; }
    .summary__line:first-child {
      margin-top: 15px; }
    .summary__line p {
      line-height: 1;
      display: inline;
      font-size: 2rem; }
  .summary__amount {
    font-family: 'FPT Heavy', sans-serif;
    font-weight: bold;
    font-size: 2rem; }
  .summary__unit {
    font-family: 'FPT Medium', sans-serif;
    font-size: 20px; }
  .summary__squared {
    vertical-align: super;
    font-size: 12px; }

.team {
  background-color: var(--color-white);
  border-radius: 7px;
  color: var(--color-white);
  font-size: 18px;
  margin-bottom: 2rem;
  position: relative; }
  .team label {
    margin-bottom: 0; }
  .team__name {
    display: flex;
    justify-content: space-between;
    padding: 12px 20px;
    border-radius: 5px;
    cursor: pointer; }
  .team__info {
    display: flex;
    align-items: center; }
  .team__details {
    padding: 0 3rem;
    padding-top: 2rem;
    padding-bottom: 3rem; }
  .team__details-name {
    font-family: 'FPT Medium';
    font-size: 1.8rem;
    color: #000;
    margin-right: 2rem; }
  .team__delete {
    z-index: 20;
    position: absolute;
    top: 1rem;
    right: 5rem;
    cursor: pointer; }
  .team__name:after {
    content: url("../assets/img/arrow-down.svg"); }

.results__team {
  display: flex;
  flex-direction: column;
  border: dashed 1px var(--gray-dark-3);
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 1rem; }

.results__team-title {
  display: flex;
  justify-content: space-between;
  border: solid 1px var(--gray-dark-2);
  border-radius: 5px;
  padding: 8px 2rem;
  color: var(--color-white);
  align-items: center; }

.results__team-name {
  font-size: 1.8rem;
  line-height: 1; }

.results__team-details {
  padding: 15px;
  border-radius: 5px;
  color: #000; }

.results__team-details-name {
  font-family: 'FPT Light';
  color: var(--gray-light-5); }

.results__team-details-unit {
  color: var(--gray-light-3); }

.results__team-details-line {
  display: flex;
  justify-content: space-between; }

.details__input {
  padding: 8px;
  font-size: 18px;
  width: 100%;
  border: solid 1px var(--gray-dark-2); }

.toolbar {
  display: flex;
  margin: 0 auto;
  background: #FBFBFB;
  width: 225px;
  box-shadow: 0px 2px 0px rgba(54, 54, 54, 0.5);
  margin-bottom: 5px;
  position: absolute;
  top: 0;
  justify-content: center;
  align-items: center;
  margin-left: 50%;
  transform: translateX(-50%); }
  .toolbar__icon {
    color: #C7C7C7; }
  .toolbar__item {
    padding: 1.5rem;
    cursor: pointer;
    width: 75px;
    display: flex;
    justify-content: center; }
  .toolbar__active {
    background-color: #D8D8D8; }
    .toolbar__active .toolbar__icon {
      color: #868585; }

.body {
  flex: 1 0 80%;
  background-color: #fff;
  border-radius: 15px;
  height: calc(100vh - 200px);
  margin-right: 3rem;
  position: relative;
  padding-top: 5rem;
  padding-bottom: 2rem; }

.content {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  padding-left: 8rem;
  padding-top: 4rem;
  padding-right: 6rem; }

.footer__list {
  display: flex;
  justify-content: center;
  list-style: none; }

.footer p {
  font-size: 1.1rem; }

.header {
  background-color: var(--color-primary);
  height: 82px;
  display: flex;
  padding-left: 3rem;
  align-items: center; }
  .header__logo {
    width: 115px; }

.sider {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 0 20%; }

.splash-screen {
  display: flex;
  flex-direction: column;
  background-color: var(--color-primary);
  color: #FFF;
  height: 100vh; }
  .splash-screen__title {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    justify-content: center; }
    .splash-screen__title :last-child {
      margin-top: 5rem; }
  .splash-screen__footer {
    padding-bottom: 5rem;
    align-self: center;
    font-size: 1.1rem !important; }
    .splash-screen__footer p {
      font-size: 1.1rem; }
  .splash-screen__list {
    font-size: 1.1rem;
    list-style: none;
    display: flex;
    justify-content: space-around;
    width: 80%;
    margin: 0 auto;
    margin-top: 2rem; }
  .splash-screen__list-item {
    color: var(--color-white);
    text-decoration: none; }
    .splash-screen__list-item:hover {
      color: inherit; }
